<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <!-- <v-btn
        @click="newBulkMedleeDialog = !newBulkMedleeDialog"
        v-if="userData.role == 'superadmin'"
        >MEDLE.MN</v-btn
      > -->
      <!-- YTODO -->
      <v-card>
        <div class="card-header-padding" v-if="withHeadInfo">
          <v-row justify="start">
            <v-col cols="6">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ title }} <span v-if="users2">({{ users2.length }})</span>
              </h5>
              <h5
                class="text-typo"
                v-if="
                  userData && userData.school && userData.role == 'superadmin'
                "
              >
                <!-- {{ userData.school.name }} {{ userData.school.currentYear }} -->
                <!-- YTODO -->
              </h5>
              <p class="text-sm text-body mb-0">
                {{ description }}
              </p>
            </v-col>
            <v-col cols="6" class="text-end">
              <!-- <v-btn
                v-if="
                  filterSelectedDepartment &&
                  filterSelectedDepartmentClassGroup &&
                  userData.role == 'superadmin' &&
                  users2 &&
                  users2.length > 0
                "
                @click="_deleteAll"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-danger bg-gradient-danger py-3 px-6 ml-auto mr-2"
                >Устгаx</v-btn
              >
              <v-btn
                v-if="userData.role == 'superadmin'"
                @click="
                  allStudentBulkAdd = true;
                  newBulkDialog = true;
                "
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger mr-4"
                >Сурагчдыг бөөнөөр оруулаx</v-btn
              >
              <v-btn
                v-if="userData.role == 'superadmin'"
                @click="_deleteAllStudent"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger"
                >Бүгдийг устгаx</v-btn
              >
              <v-btn
                v-if="
                  filterSelectedDepartment &&
                  filterSelectedDepartmentClassGroup &&
                  userData.role == 'superadmin'
                "
                @click="newBulkDialog = true"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger"
                >Бөөнөөр оруулаx</v-btn
              > -->
              <v-btn
                @click="_showNewDialog"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-primary bg-gradient-primary py-3 px-6 ml-4"
                >Сурагч+</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-card-title>
          <v-col>
            <v-row>
              <v-col cols="4" lg="4" md="4" sm="6" v-if="departments">
                <v-select
                  clearable
                  v-if="departments && departments.length > 0"
                  return-object
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="filterSelectedDepartment"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="4" lg="4" md="4" sm="6">
                <v-select
                  clearable
                  return-object
                  v-if="filterSelectedDepartment"
                  :items="filterSelectedDepartment.classGroups"
                  item-text="name"
                  v-model="filterSelectedDepartmentClassGroup"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" lg="6" md="6" sm="6">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              label="Xайx"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)" :key="props.item.ref.path">
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar
                  rounded
                  :size="36"
                  class="my-0 me-5"
                  @click="_printRef(props.item)"
                >
                  <!--               
              <span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span>
               -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td>
                {{ props.item["firstName"] }}
              </td>
              <!-- <td v-if="userData.role=='superadmin'">
                {{ props.item.ref.path}}
              </td> -->
              <td>
                {{ props.item["lastName"] }}
              </td>
              <td class="blue--text"> {{ props.item["PERSON_ID"]  }} </td>
              <td>
                {{ props.item["STUDENT_GROUP_NAME"] }}
              </td>
              <td>
                <span v-if="props.item.gender == 0" class="red--text">
                  {{ props.item["genderName"] }}</span
                >
                <!-- <span v-else-if="props.item.gender == 1" class="blue--text">
                  {{ props.item["GENDER_CODE"] }}</span
                > -->
                <span> {{ props.item["GENDER_CODE"] }}</span>
              </td>
              <!-- <td> {{ props.item.ref.path  }}</td> -->
               
              <td> {{ props.item["PROGRAM_PLAN_ID"]  }} </td>
              <td> {{ props.item["DATE_OF_BIRTH"] | formatDate2 }}</td>
              <td>{{ props.item["ACTION_DATE"] | formatDate2 }}</td>
              <td>
                {{ props.item["email"] }}
              </td>
              <td>
                {{ props.item["EMAIL"] }}
              </td>
              <td>
                {{ props.item["phone"] }}
              </td>
              <td style="color: #56ca28">
                {{ props.item["lastLoggedAt"] | formatDate }}
              </td>
              <template v-if="goChild == true">
                <td v-for="chld in props.item.children" :key="chld.id">
                  {{ chld["amount"] }}
                </td>
              </template>
              <td v-if="!userData.school._esisContracted">
                <!-- <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon> -->
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon> -->
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
          <template slot="no-data"  > <span class="red--text"> Анги бүлгээ сонгоорой! </span></template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Та устгаxыг xүсэж байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
                >Үгүй</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
                >Тийм</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
    <v-dialog
      v-model="newDialog"
      max-width="500px"
      v-if="showAddButton"
      scrollable
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >{{ addButtonTitle }} нэмэx</span
          >
        </div>
        <v-card-text class="card-padding pt-0">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6">
                <label>Нэр</label>
                <v-text-field
                  v-model.trim="editedItem.firstName"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Нэр"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Овог</label>
                <v-text-field
                  v-model.trim="editedItem.lastName"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Овог"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <label>Имэйл (Gmail) - <span class="red--text">Нэвтрэx нэр болно!</span> </label>
                <v-text-field
                  v-model.trim="editedItem.email"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="6">
                <label>Утас</label>
                <v-text-field
                  v-model.trim="editedItem.phone"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col> -->
            </v-row>
<!-- 
            <v-row class="mt-0">
              <v-col cols="6">
                <label>Имэйл <span class="red--text">(MEDLE.mn)</span></label>
                <v-text-field
                  v-model.trim="editedItem.EMAIL"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label
                  >Утас2 <span class="red--text">(Эцэг, эx...)</span></label
                >
                <v-text-field
                  v-model.trim="editedItem.phone2"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <v-row class="mt-0 py-0">
              <!-- <v-col cols="4">
                <v-select
                  v-model="selectedYear"
                  :items="[2022, 2023, 2024]"
                  label="Элсэлтийн жил"
                >
                </v-select>
              </v-col> -->

              <v-col cols="6">
                <v-select
                  v-if="departments"
                  return-object
                  v-model="selectedDepartment"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                v-if="selectedDepartment && selectedDepartment.classGroups"
              >
                <v-select
                  :rules="[rules.required]"
                  return-object
                  v-model="selectedClassGroup"
                  :items="selectedDepartment.classGroups"
                  item-text="name"
                  item-value="id"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <!-- <v-col cols="4">
                <v-select
                  v-model="editedItem.genderName"
                  :items="['Эмэгтэй', 'Эрэгтэй']"
                  label="Xүйс сонгоx"
                >
                </v-select>
              </v-col> -->

              <!-- <v-col cols="8">
                <v-text-field
                  v-if="editedItem.PERSON_ID"
                  disabled
                  label="Сурагчийн ESIS код"
                  v-model.trim="editedItem.PERSON_ID"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  label="Сурагчийн ESIS код"
                  v-model.trim="editedItem.PERSON_ID"
                >
                </v-text-field>
              </v-col> -->
            </v-row>
            <v-row class="mt-0 pt-0">
              
              <v-col cols="12">
                <label class="red--text">Төрсөн өдөр - Нууц үг болно!</label>
                <v-text-field
                  label="Төрсөн өдөр (жиш: 2005-02-11) - Нууц үг болно! "
                  v-model.trim="editedItem.DATE_OF_BIRTH"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save2"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkDialog" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Нэр, овог, имэйл, утас</p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            v-if="
              filterSelectedDepartment && filterSelectedDepartmentClassGroup
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk"
          >
            Xадгалаx
          </v-btn>
          <v-btn
            v-if="allStudentBulkAdd"
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk2"
          >
            Xадгалаx2
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkMedleeDialog" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Нэр, овог, имэйл</p>
                <v-textarea
                  v-model="bulkTextMedlee"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            v-if="
              filterSelectedDepartment && filterSelectedDepartmentClassGroup
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_medlee()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
//StudentsTable
import { mapState } from "vuex";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    bulkTextMedlee: null,
    newBulkMedleeDialog: null,
    allStudentBulkAdd: false,
    selectedYear: null,
    bulkText: null,
    newBulkDialog: false,
    filterSelectedDepartment: null,
    filterSelectedDepartmentClassGroup: null,

    selectedDepartment: null,
    selectedClassGroup: null,

    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    defaultItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    rules: {
      required: (value) => !!value || "Нэр шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    withHeadInfo: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          item.avatar = require("@/assets/img/team-2.jpg");
          if (this.filterSelectedDepartmentClassGroup != null) {
            if (
              item["classGroup-" + this.userData.school.currentYear] &&
              item["classGroup-" + this.userData.school.currentYear].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              counter++;
              item.index = counter;
              list.push(item);
            }
          } else {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    this.selectedYear = this.userData.school
      ? this.userData.school.currentYear
      : null;
    if (this.userData && this.userData.school) {
      this.findDepartmentsAndClassGroups(this.selectedYear);
    }
    this.query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let student = doc.data();
        student.id = doc.id;
        student.ref = doc.ref;
        student.children = null;
        if (student.moved) {
          if (
            this.userData.school &&
            this.userData.school.currentYear &&
            student["departmentName-" + this.userData.school.currentYear]
          ) {
            student.className =
              student["departmentName-" + this.userData.school.currentYear];
            if (student["classGroupName-" + this.userData.school.currentYear])
              student.className = student.readfrom_esis
                ? student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase()
                : student.className +
                  student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase();
          }
          if (student.gender != undefined || student.gender != null) {
            student.genderName = student.gender == 1 ? "Эрэгтэй" : "Эмэгтэй";
          }

          if (student.firstName && student.firstName.length > 0) {
            if (student.firstName[0].toUpperCase() != student.firstName[0]) {
              student.firstName =
                student.firstName.charAt(0).toUpperCase() +
                student.firstName.slice(1);
              student.ref.update({ firstName: student.firstName });
            }
          }
          if (student.lastName && student.lastName.length > 0) {
            if (student.lastName[0].toUpperCase() != student.lastName[0]) {
              student.lastName =
                student.lastName.charAt(0).toUpperCase() +
                student.lastName.slice(1);
              student.ref.update({ lastName: student.lastName });
            }
          }
          this.users.push(student);
        }
      });
    });
    //this._getResults();
  },
  watch: {
    filterSelectedDepartment () {
      if (this.filterSelectedDepartment) {
        this.filterSelectedDepartmentClassGroup = null;
        fb.db
          .collection(this.path)
          .where(
            "ACADEMIC_LEVEL",
            "==",
            this.filterSelectedDepartment.index.toString()
          )
          .orderBy("firstName", "asc")
          .onSnapshot((querySnapshot) => {
            this.users = [];
            querySnapshot.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;

              // if (
              //   student["departmentName-" + this.userData.school.currentYear]
              // ) {
              //   student.className =
              //     student["departmentName-" + this.userData.school.currentYear];
              //   if (
              //     student["classGroupName-" + this.userData.school.currentYear]
              //   )
              //     student.className = student.readfrom_esis
              //       ? student[
              //           "classGroupName-" + this.userData.school.currentYear
              //         ].toUpperCase()
              //       : student[
              //           "classGroupName-" + this.userData.school.currentYear
              //         ].toUpperCase();
              // }
              if (student.deleted == false && !student.moved)
                this.users.push(student);
            });
          });
      }
    },
    filterSelectedDepartmentClassGroup: function (val) {
      if (val) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.users = [];
            docs.docs.forEach((doc) => {
              var stud = doc.data();
              stud.ref = doc.ref;
              stud.id = doc.id;
              if (!stud.moved) this.users.push(stud);
            });
          });
      }
      // if (this.users) {
      //   this.users.forEach((item) => {
      //     if (
      //       this.filterSelectedDepartmentClassGroup &&
      //       item["classGroup-" + this.userData.school.currentYear] &&
      //       item["classGroup-" + this.userData.school.currentYear].path ==
      //         this.filterSelectedDepartmentClassGroup.ref.path
      //     ) {
      //       list.push(item);
      //     }
      //   });
      // }
    },
    // selectedYear: function (val) {
    //   console.log(val);
    //   this.selectedDepartment = null;
    //   this.selectedClassGroup = null;
    //   this.findDepartmentsAndClassGroups(val);
    // },
  },
  methods: {
    _printRef(student) {
      console.log(student.ref.path);
      console.log(student);
    },
    _medlee() {
      var counter = 0;
      if (this.bulkTextMedlee != null && this.bulkTextMedlee != "") {
        var arrayOfLines = this.bulkTextMedlee.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            this.userData.school.ref
              .collection("students")
              .where("lastName", "==", lineArray[0])
              .where("firstName", "==", lineArray[1])
              .where(
                "classGroupName-2022",
                "==",
                this.filterSelectedDepartment.name +
                  "-" +
                  this.filterSelectedDepartmentClassGroup.name
              )
              .limit(1)
              .get()
              .then((docs) => {
                if (docs.empty) {
                  console.log(
                    lineArray[0],
                    lineArray[1],
                    lineArray[2],
                    "skipped"
                  );
                } else {
                  docs.forEach((doc) => {
                    let student = doc.data();
                    student.ref = doc.ref;
                    student.id = doc.id;
                    if (!student.email)
                      student.ref
                        .update({
                          email: lineArray[2].trim(),
                          EMAIL: lineArray[2].trim(),
                        })
                        .then(() => {
                          console.log(
                            student.firstName,
                            student.lastName,
                            lineArray[0],
                            lineArray[1],
                            lineArray[2],
                            ++counter
                          );
                        });
                    else
                      console.log(
                        lineArray[0],
                        lineArray[1],
                        lineArray[2],
                        student.ref,
                        "exists"
                      );
                  });
                }
              });
          });
        }
      }
    },
    _deleteAll() {
      var yes = confirm("delete all?");
      if (yes) {
        var batch = fb.db.batch();
        var list = [];
        if (this.users != null) {
          var counter = 0;
          for (var item of this.users) {
            item.avatar = require("@/assets/img/team-2.jpg");
            if (this.filterSelectedDepartmentClassGroup != null) {
              if (
                item["classGroup-" + this.userData.school.currentYear] &&
                item["classGroup-" + this.userData.school.currentYear].path ==
                  this.filterSelectedDepartmentClassGroup.ref.path
              ) {
                counter++;
                item.index = counter;
                list.push(item);
                batch.delete(item.ref);
              }
            }
          }
          batch.commit().then(() => {
            console.log(list.length);
          });
        }
      }
    },
    _deleteAllStudent() {
      var yes = confirm("delete all?");
      if (yes) {
        var batch = fb.db.batch();
        var list = [];
        if (this.users != null) {
          for (var item of this.users) {
            list.push(item);
            batch.delete(item.ref);
          }
          batch.commit().then(() => {
            console.log(list.length);
          });
        }
      }
    },
    _getNormalName(name) {
      return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
      //return name.toLowerCase()
    },
    _saveBulk2() {
      var counter = 0;
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            var newItem = {
              lastName: lineArray[1] ? lineArray[1].trim() : null,
              firstName: lineArray[1] ? lineArray[2].trim() : null,
              _gender: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
              _register: lineArray[4]
                ? lineArray[4].trim().toLowerCase()
                : null,
              register: lineArray[4] ? lineArray[4].trim().toLowerCase() : null,
              _phone: lineArray[5] ? lineArray[5].trim().toLowerCase() : null,
              phone: lineArray[5] ? lineArray[5].trim().toLowerCase() : null,
              email: lineArray[6] ? lineArray[6].trim().toLowerCase() : null,
              deleted: false,
              createdAt: new Date(),
            };

            lineArray[0] = lineArray[0].replaceAll(".", "");
            var angiBuleg = [];
            if (lineArray[0].indexOf("e") > -1) {
              angiBuleg = lineArray[0].split("-");
            } else {
              var buleg = lineArray[0].slice(-1);
              var angi = lineArray[0].substring(0, lineArray[0].length - 1);
              angiBuleg = [angi, buleg];
            }
            // console.log(angiBuleg)
            var foundedDep = this._findDepartment(angiBuleg[0]);
            if (foundedDep) {
              newItem["department-" + this.userData.school.currentYear] =
                foundedDep.ref;
              newItem["departmentName-" + this.userData.school.currentYear] =
                foundedDep.name.toString();

              var foundedClassGroup = this._findClassGroup(
                foundedDep,
                angiBuleg[1]
              );
            }
            //console.log(foundedDep, angiBuleg)

            if (foundedClassGroup) {
              newItem["classGroup-" + this.userData.school.currentYear] =
                foundedClassGroup.ref;
              newItem["classGroupName-" + this.userData.school.currentYear] =
                foundedClassGroup.name.toLowerCase();
            } else {
              console.log("not found!!!", angiBuleg);
              newItem["classGroup-" + this.userData.school.currentYear] = null;
              newItem["classGroupName-" + this.userData.school.currentYear] =
                null;
            }
            if (newItem._gender && newItem._gender == "эмэгтэй") {
              newItem.genderName = "эмэгтэй";
              newItem.gender = 0;
            } else if (newItem._gender && newItem._gender == "эрэгтэй") {
              newItem.genderName = "эрэгтэй";
              newItem.gender = 1;
            }

            newItem.enteredYear = this.userData.school.currentYear;
            newItem.role = "student";
            newItem.roleName = "Сурагч";
            newItem.schoolRef = this.userData.school.ref;

            batch.set(
              this.userData.school.ref.collection("students").doc(),
              newItem
            );
            counter++;
          });
        }
      }
      console.log(counter);
      batch.commit().then(() => {
        // this.filterSelectedDepartmentClassGroup.ref.update({
        //   numberOfStudents: counter,
        // });
        console.log(counter);
        this.bulkText = null;
        counter = 0;
        this._closeEdit();
      });
    },
    _findDepartment(depX) {
      var tmp = null;
      for (var dep of this.departments) {
        if (dep.name.toString() == depX) {
          tmp = dep;
          break;
        }
      }
      return tmp;
    },
    _findClassGroup(dep, classGroupName) {
      var tmp = null;
      //console.log(dep)
      for (var classGroup of dep.classGroups) {
        // console.log(dep.name.toString(), classGroup.name.toLowerCase(), )
        if (
          classGroup.name.toString().toLowerCase() ==
          classGroupName.toLowerCase()
        ) {
          tmp = classGroup;
          break;
        }
      }
      return tmp;
    },
    _saveBulk() {
      var counter = 0;
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            var newItem = {};
            // lineArray.forEach((doc) => {
            //   console.log(++counter, doc.trim());
            // });
            console.log(lineArray.length);
            if (lineArray.length == 2) {
              var lastName = null;
              var firstName = null;
              if (lineArray[0].includes(".")) {
                lastName = lineArray[0]
                  .trim()
                  .substring(0, lineArray[0].indexOf("."));
                firstName = lineArray[0]
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              } else if (lineArray[0].includes(" ")) {
                var fl = lineArray[0].split(" ");
                lastName = fl[0].trim();
                firstName = fl[1]
                  .trim()
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              } else {
                firstName = lineArray[0]
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              }

              newItem = {
                lastName: lastName,
                firstName: firstName,
                email: lineArray[1] ? lineArray[1].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (
              lineArray.length == 2 &&
              lineArray[0].trim().includes(" ")
            ) {
              var names = lineArray[0].trim().split(" ");
              var lastName2 = names[0].trim();
              var firstName2 = names[1].trim();
              newItem = {
                lastName: lastName2,
                firstName: firstName2,
                email: lineArray[1] ? lineArray[1].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (lineArray.length == 4) {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                phone: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (lineArray.length == 3) {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                email2: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            }

            // var newItem = {
            //   register: lineArray[0].trim(),
            //   uOvog: lineArray[1].trim(),
            //   lastName: lineArray[2].trim(),
            //   firstName: lineArray[3].trim(),
            //   gender: lineArray[4].trim() == "Эрэгтэй" ? 1 : 0,
            //   phone: lineArray[5].trim(),
            //   familyStatus: lineArray[6].trim(),
            //   address1: lineArray[7].trim(),
            //   address2: lineArray[8].trim(),
            //   address3: lineArray[9].trim(),
            // };
            newItem["department-" + this.userData.school.currentYear] =
              this.filterSelectedDepartment.ref;
            newItem["departmentName-" + this.userData.school.currentYear] =
              this.filterSelectedDepartment.index;
            newItem["classGroup-" + this.userData.school.currentYear] =
              this.filterSelectedDepartmentClassGroup.ref;
            newItem["classGroupName-" + this.userData.school.currentYear] =
              this.filterSelectedDepartmentClassGroup.name.toLowerCase();

            newItem.enteredYear = this.userData.school.currentYear;
            newItem.role = "student";
            newItem.roleName = "Сурагч";
            newItem.schoolRef = this.userData.school.ref;
            newItem.schoolIndex = "dundgovi";

            // newItem.roleRef = fb.db.doc(
            //   "schools/xUSN7smB3EcLwDYhs1Y9/xDuties/SpcksHAguzBtLl5S333H"
            // );

            batch.set(
              this.userData.school.ref.collection("students").doc(),
              newItem
            );
            counter++;
          });
        }
      }
      batch.commit().then(() => {
        // this.filterSelectedDepartmentClassGroup.ref.update({
        //   numberOfStudents: counter,
        // });
        console.log(this.filterSelectedDepartmentClassGroup.ref.path);
        console.log(
          counter,
          this.filterSelectedDepartment.name +
            this.filterSelectedDepartmentClassGroup.name
        );
        this.bulkText = null;
        counter = 0;
        this.filterSelectedDepartment = null;
        //this.filterSelectedDepartmentClassGroup = null;

        this._closeEdit();
      });
    },
    _closeEdit() {
      this.newBulkDialog = false;
      this.bulkText = null;
      this.$nextTick(() => {});
    },
    _setStudent() {
      for (var student of this.users) {
        //if(student.role==undefined) student.ref.update({role:"student", roleName:"Сурагч"})
        if (student.email && student.email.includes("gmailcom")) {
          //console.log(student.email, student.email.replace("gmailcom","gmail.com"))
          student.ref.update({
            email: student.email.replace("gmailcom", "gmail.com"),
          });
        }
        //gmailcom
      }
    },
    _showNewDialog() {
      console.log(this.userData.school.ref.path,"_esisContracted")
      if (this.userData.school._esisContracted) {
        this.$swal.fire("ESIS дээр сурагчийг бүртгээд татаx зарчмаар ажиллана");
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.newDialog = true;
      }
    },

    findDepartmentsAndClassGroups(startYear) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .where("startYear", "==", startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  this.numberOfStudents =
                    this.numberOfStudents + cgroup.numberOfStudents
                      ? cgroup.numberOfStudents
                      : 0;
                  dep.classGroups.push(cgroup);
                });
              });
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "StudentDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.update({ deleted: true }).then(() => {
        if (this.editedItem["department-" + this.userData.school.currentYear]) {
          this.editedItem[
            "department-" + this.userData.school.currentYear
          ].update({
            numberOfStudents: fb.firestore.FieldValue.increment(-1),
          });
        } else {
          fb.db.collection(this.path).parent.update({
            studentsNumber: fb.firestore.FieldValue.increment(-1),
          });
        }

        this.closeDelete();
      });
      // var collNames = ["payments", "studyHistory"];
      // var batch = fb.db.batch();
      // collNames.forEach((collName) => {
      //   this.editedItem.ref
      //     .collection(collName)
      //     .get()
      //     .then((docs) => {
      //       docs.forEach((doc) => {
      //         batch.delete(doc.ref);
      //       });
      //     });
      // });
      // batch.commit().then(() => {
      //   this.editedItem.ref.delete().then(() => {
      //     // this.users.splice(this.editedIndex, 1);
      //     this.closeDelete();
      //   });
      // });
    },
    _deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      if (item.enteredYear == undefined)
        item.enteredYear = this.userData.school.currentYear;
      this.editedItem = Object.assign({}, item);
      this.editedItem["DATE_OF_BIRTH"] = this.editedItem[
        "DATE_OF_BIRTH"
      ].replace("T00:00:00.000Z", "");

      for (const dep of this.departments) {
        if (
          this.editedItem["department-" + this.userData.school.currentYear] &&
          dep.id ==
            this.editedItem["department-" + this.userData.school.currentYear].id
        ) {
          this.selectedDepartment = dep;
          this.selectedClassGroup = {};

          if (
            this.editedItem["classGroup-" + this.userData.school.currentYear] &&
            this.editedItem[
              "classGroupName-" + this.userData.school.currentYear
            ]
          ) {
            this.selectedClassGroup =
              this.editedItem["classGroup-" + this.userData.school.currentYear];
            this.selectedClassGroup.name =
              this.editedItem[
                "classGroupName-" + this.userData.school.currentYear
              ];
            this.selectedClassGroup["ACADEMIC_LEVEL"] = this.editedItem["ACADEMIC_LEVEL"]
            this.selectedClassGroup["ACADEMIC_LEVEL_NAME"] = this.editedItem["ACADEMIC_LEVEL_NAME"]
            this.selectedClassGroup["PROGRAM_OF_STUDY_ID"] = this.editedItem["PROGRAM_OF_STUDY_ID"]
            this.selectedClassGroup["PROGRAM_PLAN_ID"] = this.editedItem["PROGRAM_PLAN_ID"]

            this.selectedClassGroup["PROGRAM_STAGE_ID"] = this.editedItem["PROGRAM_STAGE_ID"]
            this.selectedClassGroup["STUDENT_GROUP_ID"] = this.editedItem["STUDENT_GROUP_ID"]
            this.selectedClassGroup["STUDENT_GROUP_NAME"] = this.editedItem["STUDENT_GROUP_NAME"]
          }

          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _save() {
      console.log(
        this.editedItem,
        this.selectedYear,
        this.selectedClassGroup,
        this.selectedDepartment
      );
      if (
        this.editedItem.firstName &&
        this.selectedYear &&
        this.selectedClassGroup &&
        this.selectedDepartment
      ) {
        var x = {};
        x.createdAt = new Date();
        x.departmentIndex = this.selectedDepartment.index;

        if (this.editedItem.firstName.length > 0) {
          if (
            this.editedItem.firstName[0].toUpperCase() !=
            this.editedItem.firstName[0]
          ) {
            x.firstName =
              this.editedItem.firstName.charAt(0).toUpperCase() +
              this.editedItem.firstName.slice(1);
          } else x.firstName = this.editedItem.firstName;
        }

        x.enteredYear = this.selectedYear;
        x.role = "student";
        x.roleName = "Сурагч";
        x.schoolRef = this.userData.school.ref;
        x.deleted = false;
        x.memberID = this.editedItem.memberID ? this.editedItem.memberID : null;
        x["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
          ? this.editedItem["DATE_OF_BIRTH"] + "T00:00:00.000Z"
          : null;

        if (this.editedItem.genderName) {
          x.gender =
            this.editedItem.genderName == "Эрэгтэй"
              ? 1
              : this.editedItem.genderName == "Эмэгтэй"
              ? 0
              : -1;
        }

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email)
          x.email = this.editedItem.email.toLowerCase();
        if (this.editedItem.EMAIL)
          x.EMAIL = this.editedItem.EMAIL.toLowerCase();

        if (this.editedItem.phone) x.phone = this.editedItem.phone;
        if (this.editedItem.phone2) x.phone2 = this.editedItem.phone2;

        if (this.selectedClassGroup) {
          if (this.selectedClassGroup.name)
            x["classGroupName-" + this.selectedYear] =
              this.selectedClassGroup.name.toLowerCase();
          if (this.selectedClassGroup.ref)
            x["classGroup-" + this.selectedYear] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-" + this.selectedYear] =
            this.selectedDepartment.name;
          x["department-" + this.selectedYear] = this.selectedDepartment.ref;
        }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }

        x["ACADEMIC_LEVEL"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        x["ACADEMIC_LEVEL_NAME"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        // x["EMAIL"] = x.EMAIL ? x.EMAIL.toLowerCase() : null;
        x["FIRST_NAME"] = x.firstName ? x.firstName : null;
        x["GENDER_CODE"] = x.gender == 0 ? "F" : "M";
        x["LAST_NAME"] = x.lastName ? x.lastName : null;
        x["PERSON_ID"] = x["PERSON_ID"] ? x["PERSON_ID"] : null;
        x["PROGRAM_OF_STUDY_ID"] = null;
        x["PROGRAM_PLAN_ID"] = null;
        x["REGISTER_NUM"] = null;
        x["STUDENT_GROUP_ID"] = null;
        x["STUDENT_GROUP_NAME"] = x["classGroupName-" + this.selectedYear]
          ? x["classGroupName-" + this.selectedYear]
          : null;

        console.log(x);

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x, { merge: true })
            .then(() => {
              // fb.db.collection(this.path).parent.update({
              //   studentsNumber: fb.firestore.FieldValue.increment(1),
              // });

              // if (x["department-" + this.selectedYear]) {
              //   x["department-" + this.selectedYear].update({
              //     numberOfStudents: fb.firestore.FieldValue.increment(1),
              //   });
              // }

              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
        // } else {

        //   this.editedItem["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
        //     ? this.editedItem["DATE_OF_BIRTH"]+"T00:00:00.000Z"
        //     : null;
        //   this.editedItem.ref.update(this.editedItem).then(() => {
        //     this._close();
        //   });
        // }
      }
    },
    async _save2() {
      if (
        this.editedItem.firstName &&
        this.editedItem.email &&
        this.editedItem.DATE_OF_BIRTH &&
        this.selectedClassGroup &&
        this.selectedDepartment
      ) {
        var x = {};
        x.createdAt = new Date();
        x.departmentIndex = this.selectedDepartment.index;

        if (this.editedItem.firstName.length > 0) {
          if (
            this.editedItem.firstName[0].toUpperCase() !=
            this.editedItem.firstName[0]
          ) {
            x.firstName =
              this.editedItem.firstName.charAt(0).toUpperCase() +
              this.editedItem.firstName.slice(1);
          } else x.firstName = this.editedItem.firstName;
        }

        x.enteredYear = this.userData.school.currentYear;
        x.role = "student";
        x.roleName = "Сурагч";
        x.schoolRef = this.userData.school.ref;
        x.deleted = false;
        x["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
          ? this.editedItem["DATE_OF_BIRTH"] + "T00:00:00.000Z"
          : null;

        if (this.editedItem.genderName) {
          x.gender =
            this.editedItem.genderName == "Эрэгтэй"
              ? 1
              : this.editedItem.genderName == "Эмэгтэй"
              ? 0
              : -1;
        }

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email) {
          x.EMAIL = this.editedItem.email
          x.email = this.editedItem.email
          x.PERSON_ID =  this.editedItem.email.toLowerCase();
        }
         
        if (this.editedItem.EMAIL){
          x.EMAIL = this.editedItem.EMAIL.toLowerCase();
          x.email = this.editedItem.EMAIL.toLowerCase();
          x.PERSON_ID =  this.editedItem.EMAIL.toLowerCase();
        }

        if (this.editedItem.phone) x.phone = this.editedItem.phone;
        if (this.editedItem.phone2) x.phone2 = this.editedItem.phone2;

        if (this.selectedClassGroup) {
          if (this.selectedClassGroup.name)
            x["classGroupName-" + this.selectedYear] =
              this.selectedClassGroup.name.toLowerCase();
          if (this.selectedClassGroup.ref)
            x["classGroup-" + this.selectedYear] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-" + this.selectedYear] =
            this.selectedDepartment.name;
          x["department-" + this.selectedYear] = this.selectedDepartment.ref;
        }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }

        x["ACADEMIC_LEVEL"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        x["ACADEMIC_LEVEL_NAME"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        // x["EMAIL"] = x.EMAIL ? x.EMAIL.toLowerCase() : null;
        x["FIRST_NAME"] = x.firstName ? x.firstName : null;
        x["GENDER_CODE"] = x.gender == 0 ? "F" : "M";
        x["LAST_NAME"] = x.lastName ? x.lastName : null;
        // x["PERSON_ID"] = x["PERSON_ID"] ? x["PERSON_ID"] : null;
        x["PROGRAM_OF_STUDY_ID"] = this.selectedClassGroup?this.selectedClassGroup["PROGRAM_OF_STUDY_ID"]:null
        x["PROGRAM_PLAN_ID"] = this.selectedClassGroup?this.selectedClassGroup["PROGRAM_PLAN_ID"]:null
        x["STUDENT_GROUP_ID"] = this.selectedClassGroup?this.selectedClassGroup["STUDENT_GROUP_ID"]:null
        x["STUDENT_GROUP_NAME"] = this.selectedClassGroup?this.selectedClassGroup["STUDENT_GROUP_NAME"]:null
        x["REGISTER_NUM"] = null;
         
        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x, { merge: true })
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
        // } else {
        //   this.editedItem["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
        //     ? this.editedItem["DATE_OF_BIRTH"]+"T00:00:00.000Z"
        //     : null;
        //   this.editedItem.ref.update(this.editedItem).then(() => {
        //     this._close();
        //   });
        // }
      }else{
         this.$swal.fire('Нэр, имэйл, төрсөн он сар, анги, бүлгийн мэдээллийг сонгоx!')
      }
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
